<template>
    <div class="setting_wpr">
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Active Playbooks</label>
                <div class="field_wpr">
                    <Multiselect v-model="playbooks.value" v-bind="playbooks" placeholder="Assigned Playbooks.."></Multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Active Forms</label>
                <div class="field_wpr">
                    <Multiselect v-model="forms.value" v-bind="forms" placeholder="Assigned Forms.."></Multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Active Pages</label>
                <div class="field_wpr">
                    <Multiselect v-model="pages.value" v-bind="pages" placeholder="Selected Pages.."></Multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Active Pipelines</label>
                <div class="field_wpr">
                    <Multiselect v-model="pipelines.value" v-bind="pipelines" placeholder="Select Pipelines.."></Multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Active Journeys <a class="option">Refresh List</a></label>
                <div class="field_wpr">
                    <Multiselect v-model="journeys.value" v-bind="journeys" placeholder="Active Journeys.."></Multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Active Sequences</label>
                <div class="field_wpr">
                    <Multiselect v-model="sequences.value" v-bind="sequences" placeholder="Select Sequences.."></Multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Active Progress Scenarios</label>
                <div class="field_wpr">
                    <Multiselect v-model="scenarios.value" v-bind="scenarios" placeholder="Select Progress Scenarios.."></Multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Active Task</label>
                <div class="field_wpr">
                    <Multiselect v-model="tasks.value" v-bind="tasks" placeholder="Select Tasks.."></Multiselect>
                </div>
            </div>
        </div>
        <div class="form_grp">
            <div class="group_item">
                <label class="input_label">Active Tags <a class="option" @click="createTags = !createTags">Create a Tag</a></label>
                <div class="field_wpr">
                    <Multiselect v-model="tags.value" v-bind="tags" ></Multiselect>
                </div>
            </div>
        </div>
        <div v-if="createTags" class="form_grp">
            <div class="group_item">
                <label class="input_label">Create a New Tag</label>
                <div class="field_wpr has_suffix">
                    <input type="text" placeholder="Enter Tag Name.." >
                    <span class="suffix">Create</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Multiselect from '@vueform/multiselect';
    export default {
        name: "ToolAdvanced",
        components: {
            Multiselect,
        },
        data(){
            return{
                createTags: false,
                playbooks: {
                    mode: 'tags',
                    value: [],
                    options: [
                        { value: 'Playbook 1', label: 'Playbook 1' },
                        { value: 'Playbook 2', label: 'Playbook 2' }
                    ],
                    searchable: true,
                    createTag: true
                },
                forms: {
                    mode: 'tags',
                    value: [],
                    options: [
                        { value: 'Form 1', label: 'Form 1' },
                        { value: 'Form 2', label: 'Form 2' }
                    ],
                    searchable: true,
                    createTag: true
                },
                pages: {
                    mode: 'tags',
                    value: [],
                    options: [
                        { value: 'page 1', label: 'page 1' },
                        { value: 'page 2', label: 'page 2' }
                    ],
                    searchable: true,
                    createTag: true
                },
                pipelines: {
                    mode: 'tags',
                    value: [],
                    options: [
                        { value: 'Pipeline 1', label: 'Pipeline 1' },
                        { value: 'Pipeline 2', label: 'Pipeline 2' }
                    ],
                    searchable: true,
                    createTag: true
                },
                journeys: {
                    mode: 'tags',
                    value: [],
                    options: [
                        { value: 'Journey 1', label: 'Journey 1' },
                        { value: 'Journey 2', label: 'Journey 2' }
                    ],
                    searchable: true,
                    createTag: true
                },
                sequences: {
                    mode: 'tags',
                    value: [],
                    options: [
                        { value: 'sequence 1', label: 'sequence 1' },
                        { value: 'sequence 2', label: 'sequence 2' }
                    ],
                    searchable: true,
                    createTag: true
                },
                scenarios: {
                    mode: 'tags',
                    value: [],
                    options: [
                        { value: 'Scenario 1', label: 'Scenario 1' },
                        { value: 'Scenario 2', label: 'Scenario 2' }
                    ],
                    searchable: true,
                    createTag: true
                },
                tasks: {
                    mode: 'tags',
                    value: [],
                    options: [
                        { value: 'Task 1', label: 'Task 1' },
                        { value: 'Task 2', label: 'Task 2' }
                    ],
                    searchable: true,
                    createTag: true
                },
                tags: {
                    mode: 'tags',
                    value: ['System Tags', 'Email Enabled', 'SMS Enabled'],
                    options: [
                        { value: 'System Tags', label: 'System Tags' },
                        { value: 'Email Enabled', label: 'Email Enabled' },
                        { value: 'Email Disabled', label: 'Email Disabled' },
                        { value: 'Hard Bounces', label: 'Hard Bounces' },
                        { value: 'Missing Email', label: 'Missing Email' },
                        { value: 'Missing Phone', label: 'Missing Phone' },
                        { value: 'SMS Contact', label: 'SMS Contact' },
                        { value: 'SMS Enabled', label: 'SMS Enabled' },
                        { value: 'SMS Disabled', label: 'SMS Disabled' },
                        { value: 'Spam Complained', label: 'Spam Complained' },
                        { value: 'Unsubscribed', label: 'Unsubscribed' },
                        { value: 'Id Tags', label: 'Id Tags' },
                        { value: 'Tag Name', label: 'Tag Name' },
                        { value: 'Confirmed Email', label: 'Confirmed Email' },
                        { value: 'Invalid Email', label: 'Invalid Email' },
                        { value: 'Unconfirmed Email', label: 'Unconfirmed Email' },
                        { value: 'Invalid Phone', label: 'Invalid Phone' }
                    ],
                    searchable: true,
                    createTag: true
                },
            }
        },
        // created () {
        //     this.tags.value.forEach((val) => {
        //         console.log(val)
        //     });
        // },
    }
</script>